import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import Gallery from "../components/Gallery"
import { StyledLinkButton } from "../components/footer"
import NavigationLink from "../components/NavigationLink"
import LogoLink from "../components/LogoLink"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <NavigationLink />
    <LogoLink />
    <Section
      background="reel"
      title="Welcome to Oz's Baja Adventures"
      copy="Join us for a fishing trip of a lifetime! Oz's Baja Adventures is a year round sport fishing guide service. We are based out of Southern California, specializing in personal, guided, fishing trips to Baja Mexico. We make sure every detail of your fishing trip is both pleasurable and memorable."
    />
    <Section
      background="turquoise"
      title="The Destination"
      copy="Baja Mexico is a peninsula beginning just south of San Diego, CA and stretches for nearly 1000 miles all the way south to Los Cabos. It is known for it's beautiful beaches and nutrient rich, warm, turquoise blue waters, that attract many different species of fish.. We offer Fishing Tours in all parts of Baja, including Cabo San Lucas, San Jose, Magdalena Bay and Loreto. Whether you're looking for a quick trip to Cabo or a more secluded stay in Eastcape, we have you covered."
    />
    <Section
      background="elDorado"
      title="Fishing"
      copy={`Baja has come to be known as one of the premier fishing destinations in the world. On our guided tours, you can fish for just about anything, whether you're a beginner fisherman to an experienced Angler. With species ranging from Grouper and Snapper, to Sailfish and Marlin, every fishing trip is a new experience. For your fishing excursion, we only hire the finest boats and captains in all of Baja. Depending on your group's size and needs, we can charter a wide range of boats including 24' Super Pangas, 28' - 32' Cruisers,  and a variety of different Yachts. All of our charters include rod, reels, tackle, live bait and special electronics to catch the "Big One"!`}
    >
      <StyledLinkButton href="/packages">
        Explore Fishing Packages
      </StyledLinkButton>
    </Section>
    <Gallery />
  </Layout>
)

export default IndexPage
